import React from 'react'
import { Link } from 'gatsby';
import Layout from 'components/layout'

const UnderConstruction = ({location}) => {
  return (
    <Layout location={location}>
      
      <div className="flex-row">
        <div className="d-flex justify-content-center">
          
          <p className="lead font-weight-bold text-center my-5">
            We're currently working hard on our exciting new <br/> 
            website, in the meantime <Link to="/contact-us"> click here</Link> to be <br/>
             notified when the site goes live.
          </p>
          
        </div>
      </div>
    </Layout>
  )
}

export default UnderConstruction